
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'

import operating from '@/mixins/support/operating.js'

export default {
  mixins: [
    clickaway,
    operating
  ],

  data () {
    return {
      departments: {
        active: false
      }
    }
  },

  computed: {
    ...mapGetters({
      ticket: 'ticket/ticket',
      country: 'country',
      support: 'support/support'
    }),

    department: {
      get () {
        return this.ticket.department.value
      },
      set (value) {
        this.$store.dispatch('ticket/setDepartment', value)
      }
    },

    name: {
      get () {
        return this.ticket.name.value
      },
      set (value) {
        this.$store.dispatch('ticket/setName', value)
      }
    },

    email: {
      get () {
        return this.ticket.email.value
      },
      set (value) {
        this.$store.dispatch('ticket/setEmail', value)
      }
    },

    subject: {
      get () {
        return this.ticket.subject.value
      },
      set (value) {
        this.$store.dispatch('ticket/setSubject', value)
      }
    },

    body: {
      get () {
        return this.ticket.body.value
      },
      set (value) {
        this.$store.dispatch('ticket/setBody', value)
      }
    },

    ticketFormIsValid () {
      return !this.ticket.name.error &&
        this.ticket.name.value &&
        !this.ticket.subject.error &&
        this.ticket.subject.value &&
        !this.ticket.email.error &&
        this.ticket.email.value
    }
  },

  created () {
    this.setDepartment(this.support.ticket.departments[0])
  },

  methods: {
    hideDepartmentsDropDown () {
      this.departments.active = false
    },

    setDepartment (department) {
      this.$store.dispatch('ticket/setDepartment', department)
      this.hideDepartmentsDropDown()
    },

    validateNameOnKeydown () {
      if (this.ticket.name.value.length === 0) {
        this.$store.dispatch('ticket/validateName', '')
      }
    },

    validateNameOnKeyup () {
      if (this.ticket.name.error === '') {
        return
      }

      this.validateName()
    },

    validateName () {
      if (this.ticket.name.value.length >= 1) {
        this.$store.dispatch('ticket/validateName', '')
      } else if (this.ticket.name.value.length >= 1 && this.ticket.name.value.length < 3) {
        this.$store.dispatch('ticket/validateName', this.$t('support.ticket.new.name.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('ticket/validateName', this.$t('support.ticket.new.name.errors.required'))
      }
    },

    validateEmailOnKeyup () {
      if (this.ticket.email.value.length > 0) {
        this.$store.dispatch('ticket/validateEmail', '')
      }

      if (this.ticket.email.error === '') {
        return
      }

      this.validateEmail()
    },

    validateEmail () {
      if (this.ticket.email.value === '') {
        this.$store.dispatch('ticket/validateEmail', this.$t('support.ticket.new.email.errors.required'))
      } else if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(this.ticket.email.value)) {
        this.$store.dispatch('ticket/validateEmail', '')
      } else {
        this.$store.dispatch('ticket/validateEmail', this.$t('support.ticket.new.email.errors.invalid'))
      }
    },

    validateSubjectOnKeyup () {
      if (this.ticket.subject.value.length > 0) {
        this.$store.dispatch('ticket/validateSubject', '')
      }

      if (this.ticket.subject.error === '') {
        return
      }

      this.validateSubject()
    },

    validateSubject () {
      if (this.ticket.subject.value.length > 0) {
        this.$store.dispatch('ticket/validateSubject', '')
      } else {
        this.$store.dispatch('ticket/validateSubject', this.$t('support.ticket.new.subject.errors.required'))
      }
    },

    submit () {
      if (!this.ticketFormIsValid) {
        return
      }

      this.$store.dispatch('ticket/setSubmitLoading', true)

      const data = {
        department: this.ticket.department.value,
        name: this.ticket.name.value,
        email: this.ticket.email.value,
        subject: this.ticket.subject.value,
        body: this.ticket.body.value
      }

      this.$axios.$post(`${this.$i18n.locale}/${this.country.iso_code}/support/tickets`, data)
        .then((res) => {
          this.$store.dispatch('ticket/setTicket', res.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.$store.dispatch('ticket/setSubmitLoading', false)
        })
    },

    createNew () {
      this.$store.dispatch('ticket/setTicket', {
        id: null,
        user: '',
        department: this.support.ticket.departments[0],
        name: '',
        email: '',
        subject: '',
        body: ''
      })
    }
  }
}
