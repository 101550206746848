
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'

import operating from '@/mixins/support/operating.js'

export default {
  mixins: [
    clickaway,
    operating
  ],

  data () {
    return {
      departments: {
        active: false
      },
      modal: {
        end: {
          active: false,
          ending: false
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      chat: 'chat/chat',
      country: 'country',
      support: 'support/support'
    }),

    department: {
      get () {
        return this.chat.department.value
      },
      set (value) {
        this.$store.dispatch('chat/setDepartment', value)
      }
    },

    name: {
      get () {
        return this.chat.name.value
      },
      set (value) {
        this.$store.dispatch('chat/setName', value)
      }
    },

    email: {
      get () {
        return this.chat.email.value
      },
      set (value) {
        this.$store.dispatch('chat/setEmail', value)
      }
    },

    subject: {
      get () {
        return this.chat.subject.value
      },
      set (value) {
        this.$store.dispatch('chat/setSubject', value)
      }
    },

    body: {
      get () {
        return this.chat.body.value
      },
      set (value) {
        this.$store.dispatch('chat/setBody', value)
      }
    },

    startFormIsValid () {
      return !this.chat.name.error &&
        this.chat.name.value &&
        !this.chat.subject.error &&
        this.chat.subject.value &&
        !this.chat.email.error // &&
        // this.chat.email.value
    }
  },

  created () {
    this.setDepartment(this.support.chat.departments[0])
  },

  methods: {
    hideDepartmentsDropDown () {
      this.departments.active = false
    },

    setDepartment (department) {
      this.$store.dispatch('chat/setDepartment', department)
      this.hideDepartmentsDropDown()
    },

    validateNameOnKeydown () {
      if (this.chat.name.value.length === 0) {
        this.$store.dispatch('chat/validateName', '')
      }
    },

    validateNameOnKeyup () {
      if (this.chat.name.error === '') {
        return
      }

      this.validateName()
    },

    validateName () {
      if (this.chat.name.value.length >= 1) {
        this.$store.dispatch('chat/validateName', '')
      } else if (this.chat.name.value.length >= 1 && this.chat.name.value.length < 3) {
        this.$store.dispatch('chat/validateName', this.$t('support.chat.new.name.errors.length', { length: 2 }))
      } else {
        this.$store.dispatch('chat/validateName', this.$t('support.chat.new.name.errors.required'))
      }
    },

    validateEmailOnKeyup () {
      if (this.chat.email.value.length === 0 || this.chat.email.value.length > 0) {
        this.$store.dispatch('chat/validateEmail', '')
      }

      if (this.chat.email.error === '') {
        return
      }

      this.validateEmail()
    },

    validateEmail () {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(this.chat.email.value) || this.chat.email.value === '') {
        this.$store.dispatch('chat/validateEmail', '')
      } else {
        this.$store.dispatch('chat/validateEmail', this.$t('support.chat.new.email.errors.invalid'))
      }
    },

    validateSubjectOnKeyup () {
      if (this.chat.subject.value.length > 0) {
        this.$store.dispatch('chat/validateSubject', '')
      }

      if (this.chat.subject.error === '') {
        return
      }

      this.validateSubject()
    },

    validateSubject () {
      if (this.chat.subject.value.length > 0) {
        this.$store.dispatch('chat/validateSubject', '')
      } else {
        this.$store.dispatch('chat/validateSubject', this.$t('support.chat.new.subject.errors.required'))
      }
    },

    start () {
      if (!this.startFormIsValid) {
        return
      }

      this.$store.dispatch('chat/setStartLoading', true)

      const data = {
        department: this.chat.department.value,
        name: this.chat.name.value,
        email: this.chat.email.value,
        subject: this.chat.subject.value,
        body: this.chat.body.value
      }

      this.$axios.$post(`${this.$i18n.locale}/${this.country.iso_code}/support/chats`, data)
        .then((res) => {
          localStorage.setItem('chat', JSON.stringify({
            id: res.data.id,
            key: res.key
          }))

          this.$store.dispatch('chat/setChat', res.data)

          // this.$echo.channel(`chats.${this.chat.id}.client`)
          //   .listen('.support.chat.message.admin.created', (e) => {
          //     this.$store.dispatch('chat/pushMessage', e.data)

          //     Notification.requestPermission().then((status) => {
          //       if (status === 'granted') {
          //         const notification = new Notification(this.$t('websockets.chats.messages.created.title', { name: this.chat.name.value }), {
          //           icon: `${this.$config.appUrl}/favicon.ico`,
          //           body: this.$t('websockets.chats.messages.created.body', { text: e.data.text })
          //           // tag: 'chats'
          //         })

          //         console.log(notification)
          //       }
          //     })
          //   })

          this.$emit('started')
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.$store.dispatch('chat/setStartLoading', false)
        })
    }
  }
}
